import React, { useState } from "react";
import axios from "axios";

function MainPage({ onNavigate }) {
  const [salesmanSearch, setSalesmanSearch] = useState("");
  const [salesmanResults, setSalesmanResults] = useState([]);
  const [officeSearch, setOfficeSearch] = useState("");
  const [officeResults, setOfficeResults] = useState([]);

  // Search Salesmen function
  const searchSalesmen = async () => {
    if (salesmanSearch) {
      try {
        const response = await axios.get(
          `https://67334c582a1b1a4ae112fc47.mockapi.io/api/v1/Salesmen?lastName=${salesmanSearch}`
        );
        setSalesmanResults(response.data);
      } catch (error) {
        console.error("Error fetching salesmen:", error);
      }
    } else {
      setSalesmanResults([]);
    }
  };

  // Search Offices function
  const searchOffices = async () => {
    if (officeSearch) {
      try {
        const response = await axios.get(
          `https://67334c582a1b1a4ae112fc47.mockapi.io/api/v1/Offices?name=${officeSearch}`
        );
        setOfficeResults(response.data);
      } catch (error) {
        console.error("Error fetching offices:", error);
      }
    } else {
      setOfficeResults([]);
    }
  };

  // Handle Enter key for Salesmen search
  const handleSalesmanKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission
      searchSalesmen();
    }
  };

  // Handle Enter key for Offices search
  const handleOfficeKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission
      searchOffices();
    }
  };

  // Navigate to ChangeSalesman for editing
  const goToEditSalesman = (salesman) => {
    onNavigate("changeSalesman", salesman); // Pass the selected salesman data
  };

  // Navigate to ChangeOffice for editing
  const goToEditOffice = (office) => {
    onNavigate("changeOffice", office); // Pass the selected office data
  };

  return (
    <div style={{ maxWidth: "600px", margin: "0 auto", padding: "20px" }}>
      <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
        Data Management
      </h2>

      {/* Salesmen Section */}
      <div style={{ marginBottom: "30px" }}>
        <h3>Salesmen</h3>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="text"
            value={salesmanSearch}
            onChange={(e) => setSalesmanSearch(e.target.value)}
            onKeyDown={handleSalesmanKeyDown} // Trigger search on Enter key
            placeholder="Search Salesmen by Last Name..."
            style={{
              flex: 1,
              padding: "8px",
              marginRight: "10px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
          <button
            onClick={searchSalesmen}
            style={{
              padding: "8px 12px",
              borderRadius: "4px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              cursor: "pointer",
              marginRight: "10px",
            }}
          >
            Search
          </button>
          <button
            onClick={() => onNavigate("newSalesman")}
            style={{
              padding: "8px 12px",
              borderRadius: "4px",
              backgroundColor: "#28a745",
              color: "white",
              border: "none",
              cursor: "pointer",
            }}
          >
            + Add New
          </button>
        </div>
        <ul>
          {salesmanResults.map((salesman) => (
            <li
              key={salesman.id}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "8px",
                borderBottom: "1px solid #ddd",
              }}
            >
              <span>
                {salesman.firstName} {salesman.lastName} -{" "}
                {salesman.functionRole}
              </span>
              <button
                onClick={() => goToEditSalesman(salesman)}
                style={{
                  padding: "6px 10px",
                  borderRadius: "4px",
                  backgroundColor: "#28a745",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                Select
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* Offices Section */}
      <div style={{ marginBottom: "30px" }}>
        <h3>Offices</h3>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="text"
            value={officeSearch}
            onChange={(e) => setOfficeSearch(e.target.value)}
            onKeyDown={handleOfficeKeyDown} // Trigger search on Enter key
            placeholder="Search Offices by Name..."
            style={{
              flex: 1,
              padding: "8px",
              marginRight: "10px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
          <button
            onClick={searchOffices}
            style={{
              padding: "8px 12px",
              borderRadius: "4px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              cursor: "pointer",
              marginRight: "10px",
            }}
          >
            Search
          </button>
          <button
            onClick={() => onNavigate("newOffice")}
            style={{
              padding: "8px 12px",
              borderRadius: "4px",
              backgroundColor: "#28a745",
              color: "white",
              border: "none",
              cursor: "pointer",
            }}
          >
            + Add New
          </button>
        </div>
        <ul>
          {officeResults.map((office) => (
            <li
              key={office.id}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "8px",
                borderBottom: "1px solid #ddd",
              }}
            >
              <span>
                {office.name} - {office.address}
              </span>
              <button
                onClick={() => goToEditOffice(office)}
                style={{
                  padding: "6px 10px",
                  borderRadius: "4px",
                  backgroundColor: "#28a745",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                Select
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default MainPage;
