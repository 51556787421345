import React, { useState } from "react";
import axios from "axios";

function ChangeOffice({ office, goBack }) {
  const [name, setName] = useState(office.name);
  const [address, setAddress] = useState(office.address);
  const [telephone, setTelephone] = useState(office.telephone);
  const [email, setEmail] = useState(office.email);

  // Function to handle saving updates
  const handleSave = async () => {
    try {
      await axios.put(
        `https://67334c582a1b1a4ae112fc47.mockapi.io/api/v1/Offices/${office.id}`,
        { name, address, telephone, email }
      );
      alert("Office information updated successfully!");
      goBack(); // Navigate back after saving
    } catch (error) {
      console.error("Error updating office:", error);
    }
  };

  // Function to handle deleting the office
  const handleDelete = async () => {
    try {
      await axios.delete(
        `https://67334c582a1b1a4ae112fc47.mockapi.io/api/v1/Offices/${office.id}`
      );
      alert("Office deleted successfully!");
      goBack(); // Navigate back after deletion
    } catch (error) {
      console.error("Error deleting office:", error);
    }
  };

  return (
    <div
      style={{
        maxWidth: "500px",
        margin: "50px auto",
        padding: "20px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        position: "relative",
      }}
    >
      {/* Back Button */}
      <button
        onClick={goBack}
        style={{
          position: "absolute",
          top: "20px",
          right: "20px",
          padding: "6px 12px",
          borderRadius: "4px",
          backgroundColor: "#6c757d",
          color: "white",
          border: "none",
          cursor: "pointer",
        }}
      >
        Back
      </button>

      <h2 style={{ textAlign: "center", marginBottom: "20px" }}>Edit Office</h2>

      <form style={{ display: "flex", flexDirection: "column" }}>
        <label style={{ marginBottom: "10px" }}>
          Office Name:
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{
              width: "100%",
              padding: "8px",
              marginTop: "5px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </label>

        <label style={{ marginBottom: "10px" }}>
          Address:
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            style={{
              width: "100%",
              padding: "8px",
              marginTop: "5px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </label>

        <label style={{ marginBottom: "10px" }}>
          Telephone:
          <input
            type="tel"
            value={telephone}
            onChange={(e) => setTelephone(e.target.value)}
            placeholder="e.g., 123-456-7890"
            style={{
              width: "100%",
              padding: "8px",
              marginTop: "5px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </label>

        <label style={{ marginBottom: "10px" }}>
          Email:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{
              width: "100%",
              padding: "8px",
              marginTop: "5px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </label>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <button
            type="button"
            onClick={handleSave}
            style={{
              padding: "10px",
              borderRadius: "4px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              cursor: "pointer",
              width: "48%",
            }}
          >
            Save
          </button>
          <button
            type="button"
            onClick={handleDelete}
            style={{
              padding: "10px",
              borderRadius: "4px",
              backgroundColor: "#dc3545",
              color: "white",
              border: "none",
              cursor: "pointer",
              width: "48%",
            }}
          >
            Delete
          </button>
        </div>
      </form>
    </div>
  );
}

export default ChangeOffice;
