import React, { useState } from "react";
import LoginPage from "./components/LoginPage";
import MainPage from "./components/MainPage";
import NewSalesman from "./components/NewSalesman";
import ChangeSalesman from "./components/ChangeSalesman";
import NewOffice from "./components/NewOffice";
import ChangeOffice from "./components/ChangeOffice"; // Import ChangeOffice component

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [currentView, setCurrentView] = useState("main");
  const [selectedItem, setSelectedItem] = useState(null); // State to hold the selected item for editing

  const handleLogin = (email) => {
    setUsername(email);
    setIsLoggedIn(true);
  };

  // Update navigateTo function to accept an optional item parameter
  const navigateTo = (view, item = null) => {
    setCurrentView(view);
    setSelectedItem(item); // Set selected item (salesman or office) for editing
  };

  return (
    <div>
      {!isLoggedIn ? (
        <LoginPage onLogin={handleLogin} />
      ) : (
        <>
          {currentView === "main" && (
            <MainPage username={username} onNavigate={navigateTo} />
          )}

          {currentView === "newSalesman" && (
            <NewSalesman goBack={() => navigateTo("main")} />
          )}

          {currentView === "changeSalesman" && selectedItem && (
            <ChangeSalesman
              salesman={selectedItem}
              goBack={() => navigateTo("main")}
            />
          )}

          {currentView === "newOffice" && (
            <NewOffice goBack={() => navigateTo("main")} />
          )}

          {currentView === "changeOffice" && selectedItem && (
            <ChangeOffice
              office={selectedItem}
              goBack={() => navigateTo("main")}
            />
          )}
        </>
      )}
    </div>
  );
}

export default App;
