import React, { useState } from "react";
import axios from "axios";

function ChangeSalesman({ salesman, goBack }) {
  const [firstName, setFirstName] = useState(salesman.firstName);
  const [lastName, setLastName] = useState(salesman.lastName);
  const [functionRole, setFunctionRole] = useState(salesman.functionRole);
  const [phone, setPhone] = useState(salesman.phone);
  const [office, setOffice] = useState(salesman.office);

  const handleSave = async () => {
    try {
      await axios.put(
        `https://67334c582a1b1a4ae112fc47.mockapi.io/api/v1/Salesmen/${salesman.id}`,
        { firstName, lastName, functionRole, phone, office }
      );
      alert("Salesman information updated successfully!");
      goBack(); // Navigate back after saving
    } catch (error) {
      console.error("Error updating salesman:", error);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `https://67334c582a1b1a4ae112fc47.mockapi.io/api/v1/Salesmen/${salesman.id}`
      );
      alert("Salesman deleted successfully!");
      goBack(); // Navigate back after deletion
    } catch (error) {
      console.error("Error deleting salesman:", error);
    }
  };

  return (
    <div style={{ maxWidth: "600px", margin: "0 auto", padding: "20px" }}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {/* Back Button */}
        <button
          onClick={goBack}
          style={{
            marginBottom: "20px",
            padding: "10px",
            borderRadius: "4px",
            backgroundColor: "#6c757d",
            color: "white",
            border: "none",
            cursor: "pointer",
          }}
        >
          Back to Main
        </button>
      </div>

      <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
        Edit Salesman
      </h2>
      <form style={{ display: "flex", flexDirection: "column" }}>
        <label style={{ marginBottom: "10px" }}>
          First Name:
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            style={{
              width: "100%",
              padding: "8px",
              marginTop: "5px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </label>
        <label style={{ marginBottom: "10px" }}>
          Last Name:
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            style={{
              width: "100%",
              padding: "8px",
              marginTop: "5px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </label>
        <label style={{ marginBottom: "10px" }}>
          Function Role:
          <input
            type="text"
            value={functionRole}
            onChange={(e) => setFunctionRole(e.target.value)}
            style={{
              width: "100%",
              padding: "8px",
              marginTop: "5px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </label>
        <label style={{ marginBottom: "10px" }}>
          Phone Number:
          <input
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            style={{
              width: "100%",
              padding: "8px",
              marginTop: "5px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </label>
        <label style={{ marginBottom: "10px" }}>
          Office:
          <input
            type="text"
            value={office}
            onChange={(e) => setOffice(e.target.value)}
            style={{
              width: "100%",
              padding: "8px",
              marginTop: "5px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </label>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <button
            type="button"
            onClick={handleSave}
            style={{
              padding: "10px",
              borderRadius: "4px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              cursor: "pointer",
              width: "48%",
            }}
          >
            Save
          </button>
          <button
            type="button"
            onClick={handleDelete}
            style={{
              padding: "10px",
              borderRadius: "4px",
              backgroundColor: "#dc3545",
              color: "white",
              border: "none",
              cursor: "pointer",
              width: "48%",
            }}
          >
            Delete
          </button>
        </div>
      </form>
    </div>
  );
}

export default ChangeSalesman;
