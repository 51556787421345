import React, { useState } from "react";
import axios from "axios";

function NewOffice({ goBack }) {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const officeData = {
      name,
      address,
      telephone,
      email,
    };

    try {
      await axios.post(
        "https://67334c582a1b1a4ae112fc47.mockapi.io/api/v1/Offices",
        officeData
      );
      alert("New office added successfully!");
      goBack(); // Navigate back after submission
    } catch (error) {
      console.error("Error adding new office:", error);
    }

    // Clear form fields after submission (optional)
    setName("");
    setAddress("");
    setTelephone("");
    setEmail("");
  };

  return (
    <div
      style={{
        maxWidth: "500px",
        margin: "50px auto",
        padding: "20px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        position: "relative",
      }}
    >
      {/* Back Button */}
      <button
        onClick={goBack}
        style={{
          position: "absolute",
          top: "20px",
          right: "20px",
          padding: "6px 12px",
          borderRadius: "4px",
          backgroundColor: "#6c757d",
          color: "white",
          border: "none",
          cursor: "pointer",
        }}
      >
        Back
      </button>

      <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
        Add New Office
      </h2>

      <form
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <label style={{ marginBottom: "10px" }}>
          Office Name:
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            style={{
              width: "100%",
              padding: "8px",
              marginTop: "5px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </label>

        <label style={{ marginBottom: "10px" }}>
          Address:
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
            style={{
              width: "100%",
              padding: "8px",
              marginTop: "5px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </label>

        <label style={{ marginBottom: "10px" }}>
          Telephone:
          <input
            type="tel"
            value={telephone}
            onChange={(e) => setTelephone(e.target.value)}
            placeholder="e.g., 123-456-7890"
            required
            style={{
              width: "100%",
              padding: "8px",
              marginTop: "5px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </label>

        <label style={{ marginBottom: "10px" }}>
          Email:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={{
              width: "100%",
              padding: "8px",
              marginTop: "5px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </label>

        <button
          type="submit"
          style={{
            padding: "10px",
            borderRadius: "4px",
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            cursor: "pointer",
            marginTop: "10px",
            alignSelf: "center",
            width: "100px",
          }}
        >
          Submit
        </button>
      </form>
    </div>
  );
}

export default NewOffice;
