import React, { useState } from "react";
import axios from "axios";

function NewSalesman() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [functionRole, setFunctionRole] = useState("");
  const [office, setOffice] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [startDate, setStartDate] = useState("");
  const [notes, setNotes] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Format the start date to ISO string format, which MockAPI expects
    const formattedStartDate = new Date(startDate).toISOString();

    const salesmanData = {
      firstName,
      lastName,
      functionRole,
      office,
      email,
      phone: formatPhoneNumber(phone), // Format phone with hyphens
      startDate: formattedStartDate,
      notes,
    };

    try {
      // Correct endpoint with lowercase "salesmen"
      const response = await axios.post(
        "https://67334c582a1b1a4ae112fc47.mockapi.io/api/v1/Salesmen",
        salesmanData
      );
      console.log("Response:", response.data); // Log the response to confirm success
    } catch (error) {
      console.error("Error submitting salesman data:", error); // Log any errors
    }

    // Clear form fields after submission (optional)
    setFirstName("");
    setLastName("");
    setFunctionRole("");
    setOffice("");
    setEmail("");
    setPhone("");
    setStartDate("");
    setNotes("");
  };

  // Function to format phone number to "XXX-XXX-XXXX"
  const formatPhoneNumber = (phone) => {
    // Only format if the phone has exactly 10 digits
    const cleaned = ("" + phone).replace(/\D/g, "");
    if (cleaned.length === 10) {
      return cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    }
    return phone; // Return original if not 10 digits
  };

  return (
    <div
      style={{
        maxWidth: "500px",
        margin: "50px auto",
        padding: "20px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
        New Salesman
      </h2>
      <form
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <label style={{ marginBottom: "10px" }}>
          First Name:
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
            style={{
              width: "100%",
              padding: "8px",
              marginTop: "5px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </label>
        <label style={{ marginBottom: "10px" }}>
          Last Name:
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
            style={{
              width: "100%",
              padding: "8px",
              marginTop: "5px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </label>
        <label style={{ marginBottom: "10px" }}>
          Function:
          <input
            type="text"
            value={functionRole}
            onChange={(e) => setFunctionRole(e.target.value)}
            placeholder="e.g., Sales Associate, Sales Manager"
            required
            style={{
              width: "100%",
              padding: "8px",
              marginTop: "5px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </label>
        <label style={{ marginBottom: "10px" }}>
          Office:
          <input
            type="text"
            value={office}
            onChange={(e) => setOffice(e.target.value)}
            required
            style={{
              width: "100%",
              padding: "8px",
              marginTop: "5px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </label>
        <label style={{ marginBottom: "10px" }}>
          Email:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={{
              width: "100%",
              padding: "8px",
              marginTop: "5px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </label>
        <label style={{ marginBottom: "10px" }}>
          Phone Number:
          <input
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="123-456-7890"
            required
            style={{
              width: "100%",
              padding: "8px",
              marginTop: "5px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </label>
        <label style={{ marginBottom: "10px" }}>
          Start Date:
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            required
            style={{
              width: "100%",
              padding: "8px",
              marginTop: "5px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </label>
        <label style={{ marginBottom: "10px" }}>
          Notes:
          <textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Any additional information"
            rows="3"
            style={{
              width: "100%",
              padding: "8px",
              marginTop: "5px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          ></textarea>
        </label>
        <button
          type="submit"
          style={{
            padding: "10px",
            borderRadius: "4px",
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            cursor: "pointer",
            marginTop: "10px",
            alignSelf: "center",
            width: "100px",
          }}
        >
          Submit
        </button>
      </form>
    </div>
  );
}

export default NewSalesman;
